import proj4 from 'proj4';

import {AreaTypeIds} from '../constants/area-types'; // eslint-disable-line no-unused-vars, max-len
import {
  poiCategories,
  PoiCategoryIds // eslint-disable-line no-unused-vars
} from '../constants/pois';

// Check needed as tests somehow fail without.
if (proj4) {
  proj4.defs(
    'EPSG:3035',
    '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 ' +
      '+towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
}

// Check needed as tests somehow fail without.
if (proj4) {
  proj4.defs(
    'EPSG:3035',
    '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 ' +
      '+towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
}

/* eslint-disable no-unused-vars */

/**
 * Fake the loading of POIs
 */
export default function(
  center: google.maps.LatLngLiteral,
  areaTypeId: AreaTypeIds,
  poiCategoryIds?: PoiCategoryIds[]
): Promise<GeoJSON.FeatureCollection<GeoJSON.Point>> {
  return new Promise((resolve, reject) => {
    const projectedCenter = proj4('EPSG:3035', [center.lng, center.lat]);
    const lng = projectedCenter[0];
    const lat = projectedCenter[1];
    const keys: string[] = [];
    const categories =
      poiCategoryIds && poiCategoryIds.length > 0
        ? poiCategories.filter(category => poiCategoryIds.includes(category.id))
        : poiCategories;

    categories.forEach(category => {
      if (category.areaTypes.includes(areaTypeId)) {
        keys.push(category.key);
      }
    });

    const url = encodeURI(
      'https://geoserver.ggr-planung.de/geoserver/projektcheck/' +
        'wfs?service=WFS&request=GetFeature&version=2.0.0' +
        '&typeNames=projektcheck:projektcheck_deutschland' +
        '&CQL_FILTER=' +
        `projektcheck_category IN (${keys.map(key => `'${key}'`).join(',')})` +
        ` AND DWithin(geom,POINT(${lat} ${lng}),5000,meters)` +
        '&count=10000&outputFormat=application/json&srsname=EPSG:4326'
    );

    fetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(pois => resolve(pois))
      .catch(error => reject(error));
  });
}
