/**
 * Load the isochrones from the API
 *
 * @see http://dev.opentripplanner.org/apidoc/1.0.0/resource_LIsochrone.html
 */
export default function(
  center: google.maps.LatLngLiteral
): Promise<GeoJSON.FeatureCollection<GeoJSON.MultiPolygon>> {
  return new Promise((resolve, reject) => {
    const travelModeCalls = ['CAR', 'BICYCLE', 'WALK'].map(travelMode => {
      const url =
        'https://projektcheck.ggr-planung.de/otp/routers/' +
        'deutschland/isochrone?routerId=deutschland&algorithm=accSampling' +
        `&fromPlace=${center.lat},${center.lng}&maxWalkDistance=4000` +
        `&mode=${travelMode}&cutoffSec=600`;

      return fetch(url, {
        method: 'get',
        headers: {
          accept: 'application/json'
        }
      })
        .then(response => response.json())
        .then(json => {
          const isochrone: any = json;

          isochrone.features[0].properties = Object.assign(
            {},
            isochrone.features[0].properties,
            {travelMode}
          );
          return isochrone;
        });
    });

    Promise.all(travelModeCalls)
      .then(allIsochrones => {
        const collection: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon> = {
          type: 'FeatureCollection',
          features: allIsochrones.map(isochrone => isochrone.features[0])
        };

        resolve(collection);
      })
      .catch(error => reject(error));
  });
}
