import {IEcologyLayerInformation} from '../interfaces/store';

/**
 * Load the ecology layer info
 */
export default function({
  bounds,
  layers,
  x,
  y,
  width,
  height
}): Promise<IEcologyLayerInformation> {
  return new Promise((resolve, reject): void => {
    const params = [
      'SERVICE=WMS',
      'VERSION=1.1.1',
      'REQUEST=GetFeatureInfo',
      'FORMAT=image/png',
      'TRANSPARENT=true',
      `QUERY_LAYERS=${layers}`,
      'STYLES',
      `LAYERS=${layers}`,
      'INFO_FORMAT=application/json',
      'FEATURE_COUNT=50',
      `X=${x}`,
      `Y=${y}`,
      'SRS=EPSG:4326',
      `WIDTH=${width}`,
      `HEIGHT=${height}`,
      `BBOX=${bounds.west},${bounds.south},${bounds.east},${bounds.north}`
    ];

    const url = `https://geoserver.ggr-planung.de/geoserver/projektcheck/wms?${params.join(
      '&'
    )}`;

    fetch(url)
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
}
