import React from 'react';

import WmsMapType from '../../libs/wms-map-type';

interface IProps {
  mapCanvas: google.maps.Map | null;
  show: boolean;
}

/**
 * An population density WMS overlay over the map
 */
export default class extends React.Component<IProps> {
  /**
   * The actual overlay
   */
  overlay: WmsMapType | null = null;

  /**
   * There are new props here
   */
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    const {mapCanvas, show} = nextProps;

    if (!mapCanvas || (show === this.props.show && this.overlay)) {
      return;
    }

    if (this.overlay) {
      this.overlay.removeFromMap(mapCanvas);
      this.overlay = null;
    }

    if (!show) {
      return;
    }

    this.overlay = new WmsMapType('ggr', {
      url: 'https://geoserver.ggr-planung.de/geoserver/projektcheck/wms?',
      version: '1.1.0',
      layers: [{id: 'projektcheck:ew_zensus'}]
    });
    this.overlay.setOpacity(0.8);

    this.overlay.addToMap(mapCanvas);
  }

  /**
   * Whether the component should update or not
   */
  shouldComponentUpdate(): boolean {
    return false;
  }

  /**
   * Render the Component
   */
  render(): null {
    return null;
  }
}
