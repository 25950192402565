import React, {ReactNode} from 'react';

import MapEcology from '../../map/map-ecology';

import {Ecologies} from '../../../constants/ecologies';

import styles from './styles.styl';
import appStyles from '../app/styles.styl';

import {IEcologyLayerData, IEcology} from '../../../interfaces/ecology';
import {ViewIds, ViewTitles} from '../../../constants/views';
import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

/**
 * All ecologies for printing
 */
const EcologiesPrint = (): JSX.Element => (
  <>
    <Ecology
      ecology={Ecologies.get(ViewIds.EcologyLandscapeProtection)}
      title={ViewTitles.get(ViewIds.EcologyLandscapeProtection)}
      view={ViewIds.EcologyLandscapeProtection}
      source={
        <>
          Quelle der Flächenabgrenzungen: Bundesamt für Naturschutz (BfN) auf
          Basis der Meldungen der Bundesländer.
          <br />
          <br /> Die Bezeichnungen der einzelnen Schutzgebiete werden in
          Web-Check durch Anklicken der entsprechenden Flächen angezeigt.
        </>
      }
    />
    <Ecology
      ecology={Ecologies.get(ViewIds.EcologySpeciesProtection)}
      title={ViewTitles.get(ViewIds.EcologySpeciesProtection)}
      view={ViewIds.EcologySpeciesProtection}
      source={
        <>
          Quelle der Flächenabgrenzungen: Bundesamt für Naturschutz (BfN) auf
          Basis der Meldungen der Bundesländer.
          <br />
          <br /> Die Bezeichnungen der einzelnen Schutzgebiete werden in
          Web-Check durch Anklicken der entsprechenden Flächen angezeigt.
        </>
      }
    />
    <Ecology
      ecology={Ecologies.get(ViewIds.EcologyHighVoltageSupplyLines)}
      title={ViewTitles.get(ViewIds.EcologyHighVoltageSupplyLines)}
      view={ViewIds.EcologyHighVoltageSupplyLines}
      source={
        <>
          Quelle für den Verlauf der Freileitungen: Open Street Map und
          Mitwirkende
        </>
      }
    />
  </>
);

const Ecology = ({
  ecology,
  title,
  view,
  source
}: {
  ecology?: IEcology;
  title?: string;
  view: ViewIds;
  source: ReactNode;
}): JSX.Element => (
  <PrintPage title={title}>
    <PrintParagraph
      aside={
        <>
          <h3>{ecology?.description}</h3>
          <p className={appStyles.source}>{source}</p>
        </>
      }
      main={
        <>
          <MapEcology view={view} />
          <EcologyPrintLegend layers={ecology?.data.layers} />
        </>
      }
    />
  </PrintPage>
);

interface ILegendProps {
  readonly layers?: IEcologyLayerData[];
}

const EcologyPrintLegend = ({layers}: ILegendProps): JSX.Element => (
  <div className={styles.legend}>
    {layers?.map(({id, name, color}) =>
      color ? (
        <span key={id} className={styles.layerPrintLegend}>
          <div className={styles.layerLegendColor}>
            <svg
              viewBox="0 0 14 14"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <rect fill={color} x="0" y="0" width="14" height="14" />
            </svg>
          </div>
          <div className={styles.layerLegendDescription}>{name}</div>
        </span>
      ) : (
        <span key={id} className={styles.layerPrintLegend}>
          <div className={styles.layerLegendIcon}>
            <img
              src={`https://geoserver.ggr-planung.de/geoserver/projektcheck/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&wodth=20&height=20&layer=${id}`}
            />
          </div>
          <div className={styles.layerLegendDescription}>{name}</div>
        </span>
      )
    )}
  </div>
);

export default EcologiesPrint;
